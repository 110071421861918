import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { Book } from '../components/common/Book';

type IProps = {
  data: any;
};

const IndexRoute: React.FC<IProps> = (props) => {
  const { title } = props.data.site.siteMetadata;
  const books = [{
    title: 'プログラミングのはじめ',
    url: 'https://programming.gitbook.su-u.dev/',
  }]

  return (
    <Layout>
      <Helmet>
        <title>{`Books - ${title}`}</title>
        {/*<meta name="description" content={subtitle} />*/}
      </Helmet>
      {/*<div className="columns is-flex-direction-row-reverse">*/}
      {/*<div className="column is-9-tablet">*/}
      <Posts>
        {books.map(({ title, url }) => {
          return (
            <Book
              key={title}
              title={title}
              slug={url}
            />
          );
        })}
      </Posts>
      {/*</div>*/}
      {/*<SideMenu />*/}
      {/*</div>*/}
    </Layout>
  );
};

const Posts = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export default IndexRoute;

export const pageQuery = graphql`
  query BookIndexQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allJsonJson {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
