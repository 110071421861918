import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Card, CardContent } from './Card';

type IProps = {
  title: string;
  slug: string;
};

export const Book: React.FC<IProps> = ({ title, slug }) => {
  return (
    <StyledCard>
      <CardContent>
        <h2 className="title">
          <Link to={slug}>{title}</Link>
        </h2>
        {/*<Description>{description}</Description>*/}
        <Link to={slug}>Read</Link>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .category {
    font-size: 16px;
    margin-left: 20px;
  }
  .title {
    margin: 20px 0;
    > a {
      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.82);
      }
      color: rgba(0, 0, 0, 0.82);
    }
  }
`;

const Description = styled.p`
  margin-bottom: 12px;
`;
